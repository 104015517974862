<template>
  <el-form
    class="login-form"
    :rules="rules"
    :model="model"
    ref="form"
    :validate-on-rule-change="false"
  >
    <div class="row">
      <div class="col-12">
        <el-form-item prop="role">
          <el-radio-group v-model="model.register_type" style="display: flex;">
            <el-radio label="student" class="mr-4 mb-0" border>
              <i class="fas fa-user-graduate"></i>
              STUDENT
            </el-radio>
            <el-radio label="teacher" class="mb-0" border>
              <i class="fas fa-chalkboard-teacher"></i>
              EDUCATOR
            </el-radio>
          </el-radio-group>
        </el-form-item>
      </div>
    </div>
    <div class="row">
      <div class="col-md-6">
        <el-form-item prop="first_name">
          <el-input
            v-model="model.first_name"
            :placeholder="$t('registerDetail.firstName')"
            prefix-icon="el-icon-user"
            maxlength="100"
          ></el-input>
        </el-form-item>
      </div>
      <div class="col-md-6">
        <el-form-item prop="last_name">
          <el-input
            v-model="model.last_name"
            :placeholder="$t('registerDetail.lastName')"
            prefix-icon="el-icon-user"
            maxlength="100"
          ></el-input>
        </el-form-item>
      </div>
    </div>
    <el-form-item prop="email" :error="errors.email">
      <el-alert
        v-show="needLogin"
        type="error"
        :closable="false"
        style="margin-bottom:10px"
      >
        The email has already been taken,
        <a :href="'/login?email=' + model.email">Pelase click here to login</a>.
      </el-alert>
      <el-input
        v-model="model.email"
        :placeholder="$t('login.email')"
        prefix-icon="el-icon-message"
      ></el-input>
    </el-form-item>
    <el-form-item prop="password">
      <el-input
        v-model="model.password"
        :placeholder="$t('login.password')"
        type="password"
        show-password
        prefix-icon="el-icon-lock"
      ></el-input>
    </el-form-item>
    <el-form-item prop="verifyPassword">
      <el-input
        v-model="model.verifyPassword"
        :placeholder="$t('login.verify_password')"
        type="password"
        show-password
        prefix-icon="el-icon-lock"
      ></el-input>
    </el-form-item>
    <el-form-item
      prop="graduation_year"
      v-if="model.register_type === 'student'"
    >
      <GradeLevelSelector
        :value="model.graduation_year"
        @change="
          value => {
            model.graduation_year = value;
          }
        "
      />
    </el-form-item>
    <el-form-item
      prop="organization_name"
      v-if="model.register_type === 'teacher'"
    >
      <el-input
        v-model="model.organization_name"
        placeholder="Company Name"
        prefix-icon="el-icon-s-flag"
        maxlength="100"
      ></el-input>
    </el-form-item>
    <el-form-item prop="country">
      <vue-country-intl
        v-model="model.country"
        :showAreaCode="false"
        placeholder="Select Country"
        type="country"
      >
        <span slot="vueCountryNoData">
          No Data
        </span>
      </vue-country-intl>
    </el-form-item>
    <el-form-item prop="g-recaptcha-response">
      <GRecaptcha
        ref="recaptcha"
        @getValidateCode="getValidateCode"
      ></GRecaptcha>
    </el-form-item>
    <p class="terms-text">
      By registering, you agree to our
      <router-link
        :to="{
          name: 'Terms'
        }"
        target="_blank"
        class="terms-link"
        >terms and conditions</router-link
      >.
    </p>
    <el-form-item align="right">
      <el-button
        type="primary"
        style="width: 100%;"
        block
        @click="handleRegister"
        >{{ $t("login.register") }}</el-button
      >
    </el-form-item>
  </el-form>
</template>
<script>
/*eslint-disable no-unused-vars */
import { mapState, mapGetters, mapActions } from "vuex";
/*eslint-enable */
import formMixin from "@/mixins/form";
import GRecaptcha from "@/components/GRecaptcha";
import GradeLevelSelector from "@/views/login/GradeLevel";
import moment from "moment";

export default {
  mixins: [formMixin],
  components: {
    GRecaptcha,
    GradeLevelSelector
  },
  data: () => {
    return {
      model: {
        register_type: "student",
        organization_name: null,
        first_name: "",
        last_name: "",
        email: "",
        password: "",
        verifyPassword: "",
        captcha: "",
        referral_code: "",
        "g-recaptcha-response": "",
        education_level: 4,
        graduation_year: "",
        country: ""
      },
      needLogin: false,
      errors: {
        email: "",
        captcha: ""
      },
      validateCode: false,
      isNext: false,
      disabled: true
    };
  },
  computed: {
    rules() {
      const checkVerifyPassword = (rule, value, callback) => {
        if (value !== this.model.password) {
          callback(new Error(this.$t("register.please_same_verify_password")));
        } else {
          callback();
        }
      };

      const checkEmail = (rule, value, callback) => {
        // 验证邮箱的正则表达式
        const regEmail = /^([a-zA-Z0-9_-])+@([a-zA-Z0-9_-])+(\.[a-zA-Z0-9_-])+/;
        if (regEmail.test(value)) {
          callback(new Error(this.$t("register.please_valid_email")));
        } else {
          callback();
        }
      };
      const checkOrganization_name = (rule, value, callback) => {
        if (this.model.register_type === "teacher") {
          if (this.model.organization_name !== "") {
            callback();
          } else {
            callback(new Error("Company Name is required!"));
          }
        } else {
          callback();
        }
      };

      return {
        organization_name: {
          validator: checkOrganization_name,
          trigger: "blur"
        },
        first_name: [
          {
            required: true,
            message: this.$t("register.please_input"),
            trigger: "blur"
          }
        ],
        last_name: [
          {
            required: true,
            message: this.$t("register.please_input"),
            trigger: "blur"
          }
        ],

        email: [
          // { validator: checkEmail, trigger: "blur" },
          {
            required: true,
            message: this.$t("register.please_input_email"),
            trigger: "blur"
          },
          {
            type: "email",
            message: this.$t("register.please_valid_email"),
            trigger: "blur"
          }
        ],
        password: [
          {
            required: true,
            message: this.$t("register.please_input_password"),
            trigger: "blur"
          }
        ],
        verifyPassword: [{ validator: checkVerifyPassword, trigger: "blur" }],
        captcha: [
          {
            required: true,
            message: this.$t("register.please_input_captcha"),
            trigger: "blur"
          }
        ],
        "g-recaptcha-response": [
          {
            required: true,
            message: this.$t("register.please_input_captcha"),
            trigger: "blur"
          }
        ],
        graduation_year: [
          {
            required: true,
            message: "Please select a grade level",
            trigger: "change"
          }
        ],
        country: [
          {
            required: true,
            message: "Please select your country",
            trigger: "change"
          }
        ]
      };
    },
    ...mapGetters({
      captchaImg: "captcha/img",
      captchaKey: "captcha/key"
    })
  },

  mounted() {
    const today = moment().format();
    const year = today.substring(0, 5);
    const month = today.substring(5, 7);
    const offset = parseInt(month) < 7 ? 0 : 1;
    let curYear = parseInt(year);
    this.model.graduation_year = curYear + 2 + offset + "";

    this.getUserCountryCode();
    if (this.$route.query.referral_code) {
      this.model.referral_code = this.$route.query.referral_code;
    }
    if (this.$route.query.role) {
      if (this.$route.query.role === "educator") {
        this.model.register_type = "teacher";
      } else if (this.$route.query.role === "student") {
        this.model.register_type = "student";
      } else {
        this.model.register_type = "student";
      }
    }
    // role=educator
  },

  methods: {
    ...mapActions("user", ["register"]),
    getValidateCode(token) {
      // this.t = value;
      console.log("token:" + token);
      this.model["g-recaptcha-response"] = token;
      this.disabled = false;
    },
    async handleRegister() {
      const valid = await this.validateForm(this.$refs["form"]);
      if (!valid) {
        return;
      }

      const {
        register_type,
        organization_name,
        first_name,
        last_name,
        email,
        password,
        captcha,
        referral_code,
        education_level,
        graduation_year,
        country
      } = this.model;
      const registerReq = {
        register_type,
        organization_name:
          register_type === "teacher" ? organization_name : null,
        first_name,
        last_name,
        email,
        password,
        captcha,
        referral_code,
        education_level: register_type === "teacher" ? null : education_level,
        graduation_year: register_type === "teacher" ? null : graduation_year,
        country
      };
      registerReq["g-recaptcha-response"] = this.model["g-recaptcha-response"];

      try {
        await this.register(registerReq);
      } catch (error) {
        if (error.status === 422) {
          this.needLogin = true;
        }
        return error;
      }

      await this.$message({
        message: this.$t("message.register_success"),
        type: "success"
      });
      await this.$store.dispatch("user/getProfile");

      let nextRoutePath = localStorage.getItem("lastAccessRoutePath");
      localStorage.setItem("lastAccessRoutePath", "");
      if (!nextRoutePath || nextRoutePath == "/home") {
        this.$router.replace({ name: "SATList" });
      } else {
        this.$router.replace({ path: nextRoutePath });
      }
    },
    refreshCaptcha() {
      this.$store.dispatch("captcha/getCaptcha");
    },
    async getUserCountryCode() {
      try {
        const { geo } = await this.$store.dispatch("ipGeo/getGeoLocation");
        this.model.country = geo.toLowerCase();
      } catch (error) {
        console.error("Error fetching country code:", error);
      }
    }
  },
  watch: {
    model: {
      deep: true,
      handler() {
        this.errors = {
          email: "",
          captcha: ""
        };
        this.needLogin = false;
      }
    }
  }
};
</script>
<style scoped>
.register-row {
  height: 80vh;
}

.el-card /deep/ .el-card__header {
  background: #ff770c;
}

.clearfix {
  color: white;
}

.el-button {
  outline: none;
}
::v-deep .el-form-item__content {
  line-height: 2;
}
.terms-text {
  font-size: 14px;
  color: #606266;
  text-align: center;
  margin:0 0 22px 0;
  line-height: 1.5;
  padding: 0 10px;
}
.terms-link {
  color: #409EFF;
  text-decoration: none;
  font-weight: 500;
  transition: all 0.3s ease;
}
.terms-link:hover {
  color: #66b1ff;
  text-decoration: underline;
}
</style>
<style scoped>
::v-deep .vue-country-intl ::placeholder {
  color: #c0c4cc;
}
::v-deep .vue-country-list {
  list-style: none;
  padding: 0;
}
::v-deep .vue-country-intl .country-intl-label {
  padding: 0 15px;
  line-height: 40px;
}
::v-deep .vue-country-intl .country-intl-label span {
  vertical-align: unset;
}
::v-deep .vue-country-item:not(.selected):hover {
  color: #fff;
  background-color: var(--themeColor);
}
</style>
