<template>
  <div>
    <el-dialog
      title="Edit Email"
      :visible.sync="showDialog"
      width="50%"
      :close-on-click-modal="false"
    >
      <Register v-show="false"></Register>
      <el-form
        label-position="top"
        :rules="rules"
        :model="model"
        ref="form"
        label-width="120"
        :validate-on-rule-change="false"
      >
        <div class="row">
          <div class="col-md-12">
            <el-alert
              type="success"
              :closable="false"
              style="margin-bottom: 20px"
            >
              <span
                >For security reasons, we need to verify your new email address.
              </span>
            </el-alert>
          </div>
        </div>
        <el-form-item prop="email" label="New Email" :error="errors.email">
          <div style="display: flex; align-items: center; gap: 10px">
            <el-input
              v-model="model.email"
              placeholder="New Email"
              prefix-icon="el-icon-message"
            >
            </el-input>
            <el-button
              type="primary"
              :disabled="isCounting || !model.email"
              @click="sendVerificationCode"
            >
              <i class="el-icon-message" style="margin-right: 6px" />
              {{ countdown > 0 ? `${countdown}s` : "Get Verification Code" }}
            </el-button>
          </div>
        </el-form-item>
        <el-form-item
          prop="captcha"
          label="Verification Code"
          :error="errors.captcha"
        >
          <div style="display: flex; gap: 10px; justify-content: center">
            <div v-for="i in 6" :key="i" style="width: 40px">
              <el-input
                v-model="model.captcha[i - 1]"
                :ref="`codeInput${i}`"
                maxlength="1"
                @input.native="handleCodeInput(i)"
                @keydown.native="e => deleteNavigation(i, e)"
                @keydown.native.37.prevent="handleArrowNavigation(i, 'left')"
                @keydown.native.39.prevent="handleArrowNavigation(i, 'right')"
                @paste.native.prevent="handlePaste"
                style="text-align: center"
              ></el-input>
            </div>
          </div>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="showDialog = false">Cancel</el-button>
        <el-button type="primary" @click="handleSave">
          <i class="fas fa-save"></i>
          Save
        </el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
/*eslint-disable no-unused-vars */
import { mapState, mapGetters, mapActions } from "vuex";
/*eslint-enable */
import { user, instant } from "@ivy-way/material";
import moment from "moment";
import Register from "@/views/login/Register";
import formMixin from "@/mixins/form";
import Edit from "@/views/profile/apis/Edit";

export default {
  metaInfo() {},

  components: { Register },

  mixins: [formMixin],

  props: ["email"],
  data() {
    return {
      moment,
      countdown: 0,
      isCounting: false,
      model: {
        oldEmail: "",
        email: "",
        captcha: Array(6).fill("")
      },
      errors: {
        email: "",
        captcha: ""
      },
      showDialog: false
    };
  },
  computed: {
    user() {
      return user;
    },
    instant() {
      return instant;
    },
    rules() {
      return {
        email: [
          {
            required: true,
            message: this.$t("register.please_input_email"),
            trigger: "blur"
          },
          {
            type: "email",
            message: this.$t("register.please_valid_email"),
            trigger: "blur"
          }
        ],
        captcha: [
          {
            required: true,
            message: this.$t("register.please_input_captcha"),
            trigger: "blur"
          }
        ]
      };
    }
  },
  watch: {
    email() {
      this.model.oldEmail = this.email;
    }
  },

  mounted() {
    this.model.oldEmail = this.email;
  },

  methods: {
    openEditUserDialog() {
      this.showDialog = true;
    },
    async sendVerificationCode() {
      this.errors.email = "";
      this.errors.captcha = "";
      if (!this.model.email) {
        this.$message.error("Please enter email address first");
        return;
      }
      try {
        await Edit.sendEmailCode({ email: this.model.email });
        this.startCountdown();
        this.$message.success(
          "The verification code has been sent to your email, please check."
        );
      } catch (error) {
        if (error.status === 422) {
          this.errors.email = error.data.message;
        } else {
          this.errors.email = error.data.message;
        }
      }
    },

    startCountdown() {
      this.countdown = 60;
      this.isCounting = true;
      const timer = setInterval(() => {
        this.countdown--;
        if (this.countdown <= 0) {
          clearInterval(timer);
          this.isCounting = false;
        }
      }, 1000);
    },

    async handleSave() {
      this.errors.email = "";
      this.errors.captcha = "";
      this.$refs.form.validate(async valid => {
        if (valid) {
          try {
            // Verify code before saving
            await Edit.changeEmail({
              email: this.model.email,
              code: this.model.captcha.join("")
            });
            this.showDialog = false;
            this.$message.success("Email updated successfully");
            this.$emit("getUserProfile");
          } catch (error) {
            if (error.status === 422) {
              this.errors.captcha = error.data.message;
            } else {
              this.errors.captcha = error.data.message;
            }
          }
        }
      });
    },
    handleCodeInput(index) {
      if (this.model.captcha[index - 1]) {
        if (index < 6) {
          this.$refs[`codeInput${index + 1}`][0].focus();
        }
      }
    },
    deleteNavigation(currentIndex, event) {
      // 处理删除键
      console.log(event);
      if (event.keyCode === 8) {
        // 8是退格键的keyCode
        if (!this.model.captcha[currentIndex - 1] && currentIndex > 1) {
          this.model.captcha[currentIndex - 2] = ""; // 清空前一个输入框
          this.$nextTick(() => {
            this.$refs[`codeInput${currentIndex - 1}`][0].focus();
          });
        }
        return;
      }
    },
    handleArrowNavigation(currentIndex, direction) {
      console.log(1);
      const newIndex =
        direction === "left" ? currentIndex - 1 : currentIndex + 1;
      if (newIndex >= 1 && newIndex <= 6) {
        this.$refs[`codeInput${newIndex}`][0].focus();
      }
    },
    handlePaste(e) {
      console.log(e);
      e.preventDefault();
      // 处理带空格的粘贴（如"1 2 3 4 5 6"）
      const pasteData = (e.clipboardData || window.clipboardData)
        .getData("text")
        .replace(/[^0-9]/g, "") // 只保留数字
        .substring(0, 6)
        .split("");
      // 清空原有输入
      this.model.captcha = Array(6).fill("");
      // 填充新数据
      pasteData.forEach((char, i) => {
        this.model.captcha[i] = char;
      });

      // 自动聚焦到最后一个输入框
      const focusIndex = Math.min(pasteData.length, 5);
      this.$refs[`codeInput${focusIndex + 1}`][0].focus();
    }
  }
};
</script>

<style scoped>
.free-color {
  color: #01b884;
}

::v-deep .el-dialog__footer {
  padding: 20px;
  border-top: 1px solid #ebeef5;
}

.dialog-footer {
  display: flex;
  justify-content: flex-end;
  gap: 12px;
}
</style>

<style scoped>
::v-deep .el-form-item__label {
  line-height: 1rem;
}
::v-deep .vue-country-intl ::placeholder {
  color: #c0c4cc;
}
::v-deep .vue-country-list {
  list-style: none;
  padding: 0;
}
::v-deep .vue-country-intl .country-intl-label {
  padding: 0 15px;
  line-height: 40px;
}
::v-deep .vue-country-intl .country-intl-label span {
  vertical-align: unset;
}
::v-deep .vue-country-item:not(.selected):hover {
  color: #fff;
  background-color: var(--themeColor);
}
</style>
