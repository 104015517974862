<template>
  <div>
    <el-dialog
      title="Change Password"
      :visible.sync="showDialog"
      width="50%"
      :close-on-click-modal="false"
    >
      <el-form
        label-position="top"
        :rules="rules"
        :model="model"
        ref="form"
        label-width="120"
        :validate-on-rule-change="false"
      >
        <el-form-item
          prop="oldPassword"
          label="Old Password"
          :error="errors.oldPassword"
        >
          <el-input
            v-model="model.oldPassword"
            placeholder="Old password"
            type="password"
            prefix-icon="el-icon-lock"
          ></el-input>
        </el-form-item>
        <el-form-item prop="password" label="New Password">
          <el-input
            v-model="model.password"
            placeholder="New password"
            type="password"
            prefix-icon="el-icon-lock"
          ></el-input>
        </el-form-item>
        <el-form-item prop="verifyPassword" label="Re-enter new Password">
          <el-input
            v-model="model.verifyPassword"
            placeholder="Re-enter new password"
            type="password"
            prefix-icon="el-icon-lock"
          ></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="showDialog = false">Cancel</el-button>
        <el-button type="primary" @click="handleSave">
          <i class="fas fa-save"></i>
          Save
        </el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
/*eslint-disable no-unused-vars */
import { mapState, mapGetters, mapActions } from "vuex";
/*eslint-enable */
import { user, instant } from "@ivy-way/material";
import moment from "moment";
import formMixin from "@/mixins/form";
import Edit from "@/views/profile/apis/Edit";

export default {
  metaInfo() {},

  components: {},

  mixins: [formMixin],

  props: ["myPlans", "forAdmin"],
  data() {
    return {
      moment,
      countdown: 0,
      isCounting: false,
      model: {
        oldPassword: "",
        password: "",
        verifyPassword: ""
      },
      showDialog: false,
      errors: {
        oldPassword: ""
      }
    };
  },
  computed: {
    user() {
      return user;
    },
    instant() {
      return instant;
    },
    rules() {
      const checkVerifyPassword = (rule, value, callback) => {
        if (value !== this.model.password) {
          callback(new Error(this.$t("register.please_same_verify_password")));
        } else {
          callback();
        }
      };

      return {
        oldPassword: [
          {
            required: true,
            message: this.$t("register.please_input_password"),
            trigger: "blur"
          }
        ],
        password: [
          {
            required: true,
            message: this.$t("register.please_input_password"),
            trigger: "blur"
          }
        ],
        verifyPassword: [
          { validator: checkVerifyPassword, required: true, trigger: "blur" }
        ]
      };
    }
  },
  watch: {
    model: {
      deep: true,
      handler() {
        this.errors = {
          oldPassword: ""
        };
      }
    }
  },

  mounted() {},

  methods: {
    ...mapActions("user", ["changeLang", "logout"]),
    openEditUserDialog() {
      this.showDialog = true;
    },
    async handleLogout() {
      await this.logout();
      await this.$message({
        message: this.$t("message.logout_success"),
        type: "success"
      });
      if (this.$route.name === "Login") {
        return;
      }
      this.$router.push({
        name: "Login"
      });
    },
    async handleSave() {
      this.$refs.form.validate(async valid => {
        if (valid) {
          try {
            await Edit.changePassword({
              old_password: this.model.oldPassword,
              password: this.model.password
            });
            this.handleLogout();
          } catch (error) {
            if (error.status === 422) {
              this.errors.oldPassword = error.data.message;
            } else {
              this.errors.oldPassword = error.data.message;
            }
            // this.$message.error(error.message || "Update failed");
          }
        }
      });
    }
  }
};
</script>

<style scoped>
.free-color {
  color: #01b884;
}

::v-deep .el-dialog__footer {
  padding: 20px;
  border-top: 1px solid #ebeef5;
}

.dialog-footer {
  display: flex;
  justify-content: flex-end;
  gap: 12px;
}
</style>

<style scoped>
::v-deep .el-form-item__label {
  line-height: 1rem;
}
::v-deep .vue-country-intl ::placeholder {
  color: #c0c4cc;
}
::v-deep .vue-country-list {
  list-style: none;
  padding: 0;
}
::v-deep .vue-country-intl .country-intl-label {
  padding: 0 15px;
  line-height: 40px;
}
::v-deep .vue-country-intl .country-intl-label span {
  vertical-align: unset;
}
::v-deep .vue-country-item:not(.selected):hover {
  color: #fff;
  background-color: var(--themeColor);
}
</style>
